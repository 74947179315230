<template>
  <div
    class="relative flex flex-col items-center w-[343px] lg:w-[630px] xl:w-[791px] h-[258px] xl:h-[368px] mb-2 border border-solid border-black rounded-lg shadow-[4px_4px_0px_0px] overflow-hidden"
    :class="joined ? 'bg-[#FFEEF1]' : 'bg-[#FFFFF6]'"
  >
    <IconsSmileWithHeart
      class="absolute -left-[3.5rem] xl:left-0 -top-16 w-[115px] xl:w-auto"
    />
    <IconsSmileWithHeart
      class="absolute -right-[2rem] xl:-right-[3.2rem] -bottom-16 xl:-bottom-12 w-[115px] xl:w-auto -rotate-45"
    />
    <p
      class="w-[311px] xl:w-[555px] mt-10 xl:mt-20 text-[32px] xl:text-[56px] text-center font-bold font-Kontesa leading-8 xl:leading-[3rem]"
    >
      Join the waitlist to create your own circle!
    </p>
    <div v-if="!joined" class="relative">
      <botton
        class="absolute top-10 xl:top-[3.3rem] right-[5px] flex justify-center items-center w-[42px] xl:w-[52px] h-[42px] xl:h-[52px] rounded-full bg-black z-10 hover:scale-105 cursor-pointer"
        @click="send"
      >
        <IconsTelegram class="w-[14px] h-[14px]" />
      </botton>
      <input
        type="email"
        id="helper-text"
        aria-describedby="helper-text-explanation"
        class="relative w-[310px] xl:w-[389px] mt-9 xl:mt-12 text-xl font-Futura font-normal rounded-full bg-[#ECEBD2] text-[#252525] pl-[24px] py-3 xl:py-[17px]"
        placeholder="email"
      />
    </div>
    <div
      v-if="joined"
      class="flex flex-col justify-center w-[200px] xl:w-[296px] mt-12 xl:mt-[55px]"
    >
      <p class="text-center xl:text-2xl leading-4">Congrats!</p>
      <p class="text-center xl:text-2xl leading-4">
        You’re now on the waitlist. We’ll get back to you soon!
      </p>
    </div>
  </div>
</template>

<script setup>
const joined = ref(false);

const send = () => {
  joined.value = true;
  setTimeout(() => (joined.value = false), 20000);
};
</script>
