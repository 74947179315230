import { useQueryClient } from "@tanstack/vue-query";
import { useReferralCode } from "~/domains/auth/composables";
import { authQueries } from "~/domains/auth/queries";

export default defineNuxtPlugin(() => {
  useRouter().beforeEach(async (route) => {
    const subdomain = useSubdomain();
    if (!subdomain.value) return;
    const queryClient = useQueryClient();
    const user = await queryClient.ensureQueryData(authQueries.self());
    if (user) {
      const member = user.members.find(
        (m) => m.organization.defaultUrl.url === subdomain.value
      );
      if (!member && !route.query.referral) return;
      if (member && member.referralCode === route.query.referral) return;
      await useRouter().replace({
        ...route,
        query: {
          ...route.query,
          referral: member ? member.referralCode : undefined,
        },
      });
      return;
    } else {
      const referralCode = useReferralCode();
      const referral = route.query.referral;
      if (!referral || typeof referral !== "string") {
        return;
      }
      referralCode.value = referral;
    }
  });
});
