import revive_payload_client_8iF8zneWv3 from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.15_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_vqisfekk54ejpsfuqaqwblaaim/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_N43B5vJKCR from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.15_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_vqisfekk54ejpsfuqaqwblaaim/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2WQ7wic3Ca from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.15_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_vqisfekk54ejpsfuqaqwblaaim/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7DxNAno9et from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.31.0_vite@5.4.14_@types+node@20.17.15_sass@1._hitqpy6ea7szxsinpnammvs4dq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_uvEn59waIz from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.15_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_vqisfekk54ejpsfuqaqwblaaim/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_SOgphsoPMW from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.15_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_vqisfekk54ejpsfuqaqwblaaim/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_inRkWx7x7E from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.15_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_vqisfekk54ejpsfuqaqwblaaim/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9wRgv39NcP from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.15_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_vqisfekk54ejpsfuqaqwblaaim/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_aN2m4rgZYO from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.31.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/packages/ddc-giving-circle/.nuxt/components.plugin.mjs";
import prefetch_client_kCYMZOPbWB from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.15_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_vqisfekk54ejpsfuqaqwblaaim/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_bzmYfoyBzq from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.31.0_vite@5.4.14_@types+node@20.17.15_sass@1.83.4_t_y4vhbq44pw7kdltxnbl4jsuzvu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import sentry_client_bpJf9Oh4PE from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/@sentry+nuxt@8.51.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.30.1_@opentelemetry+api@1._muqsaxfzcslaxbkmt3ay5ulsvi/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/packages/ddc-giving-circle/.nuxt/sentry-client-config.mjs";
import plugin_wy0B721ODc from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import motion_oKSXjvao3w from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.31.0_vue@3.5.13_typescript@5.7.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import _01_vue_query_k8pj2QgNCo from "/vercel/path0/packages/ddc-giving-circle/plugins/01.vue-query.ts";
import api_client_oAhitvIGap from "/vercel/path0/packages/ddc-giving-circle/plugins/api-client.ts";
import dayjs_pGiXRjcsJO from "/vercel/path0/packages/ddc-giving-circle/plugins/dayjs.ts";
import posthog_client_fUucxKWhZE from "/vercel/path0/packages/ddc-giving-circle/plugins/posthog.client.ts";
import referral_client_bIRkrgMbmL from "/vercel/path0/packages/ddc-giving-circle/plugins/referral.client.ts";
import toast_ysMjUcU7eJ from "/vercel/path0/packages/ddc-giving-circle/plugins/toast.ts";
export default [
  revive_payload_client_8iF8zneWv3,
  unhead_N43B5vJKCR,
  router_2WQ7wic3Ca,
  _0_siteConfig_7DxNAno9et,
  payload_client_uvEn59waIz,
  navigation_repaint_client_SOgphsoPMW,
  check_outdated_build_client_inRkWx7x7E,
  chunk_reload_client_9wRgv39NcP,
  plugin_vue3_aN2m4rgZYO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kCYMZOPbWB,
  plugin_bzmYfoyBzq,
  sentry_client_bpJf9Oh4PE,
  sentry_client_config_o34nk2sJbg,
  plugin_wy0B721ODc,
  motion_oKSXjvao3w,
  _01_vue_query_k8pj2QgNCo,
  api_client_oAhitvIGap,
  dayjs_pGiXRjcsJO,
  posthog_client_fUucxKWhZE,
  referral_client_bIRkrgMbmL,
  toast_ysMjUcU7eJ
]