<template>
  <section class="bg-[#ecebd2] w-full">
    <div
      class="relative flex flex-col items-center w-full height h-screen xl:h-[130vh] overflow-hidden bg-[#0047a5]"
    >
      <div
        class="absolute top-[15%] xl:top-[10%] 2xl:top-[7.5%] px-4 flex flex-col items-center w-full overflow-hidden z-30"
      >
        <p
          class="w-full text-center text-[62px] md:text-[96px] xl:text-[116px] 2xl:text-[156px] leading-[6rem] xl:leading-[8rem] 2xl:leading-[12rem] text-[#ECEBD2] font-Kontesa font-bold text-shadow"
        >
          Giving Circles
        </p>

        <p
          class="w-[150px] xl:w-[291px] text-sm md:text-base xl:text-2xl text-center text-[#ECEBD1] font-Kontesa font-medium"
        >
          Community-driven collective mega-donations
        </p>
        <div
          class="flex flex-col justify-center items-center p-4 mt-10 xl:mt-20 w-full max-w-[437px] bg-white bg-opacity-85 rounded-2xl"
        >
          <form
            @submit.prevent="onSubmit"
            class="flex flex-col justify-between w-full h-full"
          >
            <FormField v-slot="{ field }" name="email">
              <FormItem>
                <FormLabel
                  class="block text-sm mb-2 disabled:cursor-not-allowed"
                >
                  Your email
                </FormLabel>
                <FormControl>
                  <input
                    class="w-full border border-[#3C42571F] rounded px-3 py-2 shadow shadow-[#0000000A] text-sm disabled:cursor-not-allowed disabled:bg-zinc-50"
                    placeholder="Email"
                    v-bind="field"
                    :disabled="isLoading"
                  />
                </FormControl>
                <FormMessage class="text-red-600" />
              </FormItem>
            </FormField>
            <FormField v-slot="{ field }" name="password">
              <FormItem>
                <FormLabel
                  class="block text-sm mb-2 disabled:cursor-not-allowed"
                >
                  Your password
                </FormLabel>
                <FormControl>
                  <input
                    class="w-full border border-[#3C42571F] rounded px-3 py-2 shadow shadow-[#0000000A] text-sm disabled:cursor-not-allowed disabled:bg-zinc-50"
                    placeholder="Password"
                    type="password"
                    v-bind="field"
                    :disabled="isLoading"
                  />
                </FormControl>
                <FormMessage class="text-red-600" />
              </FormItem>
            </FormField>

            <button
              class="bg-black disabled:bg-zinc-400 disabled:cursor-not-allowed rounded shadow text-white w-full mt-4 py-3 text-2xl font-Kontesa"
              type="submit"
              :disabled="isLoading"
            >
              <UiLoadingIcon v-if="isLoading" size="32px" />
              <template v-else>Sign in</template>
            </button>
            <p
              v-if="errorMessage"
              class="text-md text-red-500 mt-2 text-center h-5"
            >
              {{ errorMessage }}
            </p>
          </form>
        </div>
        <p
          class="w-[291px] mt-11 text-sm md:text-base xl:text-2xl text-center text-[#ECEBD1] font-Kontesa font-medium"
        >
          launching 2025!
        </p>
      </div>

      <!-- <div
        class="absolute top-[24rem] right-[50%] lg:right-0 lg:top-[20vh] z-0 lg:z-20"
      >
        <HomeTemporaryCircle
          :campaignArea="heroes[0].campaignArea"
          :circle-primary-color="heroes[0].circlePrimaryColor"
          :circle-secondary-color="heroes[0].circleSecondaryColor"
          :stop-rotation="true"
        />
      </div>

      <div
        class="absolute bottom-48 -right-10 lg:bottom-[10vh] lg:right-[10vw] z-0 lg:z-20"
      >
        <HomeTemporaryCircle
          :campaignArea="heroes[1].campaignArea"
          :circle-primary-color="heroes[1].circlePrimaryColor"
          :circle-secondary-color="heroes[1].circleSecondaryColor"
          :stop-rotation="true"
        />
      </div>

      <div
        class="absolute bottom-0 -left-8 lg:bottom-[20vh] lg:left-[5vw] z-0 lg:z-20"
      >
        <HomeTemporaryCircle
          :campaignArea="heroes[2].campaignArea"
          :circle-primary-color="heroes[2].circlePrimaryColor"
          :circle-secondary-color="heroes[2].circleSecondaryColor"
          :stop-rotation="true"
        />
      </div> -->

      <div
        class="absolute w-full h-[130vh] opacity-[0.17] z-10 bg-[url(/images/home/bg-texture.png)]"
      ></div>
      <div
        class="absolute top-0 xl:top-auto w-full h-[50vh] overflow-hidden z-10"
        style="
          background: radial-gradient(
            78% 87% at bottom,
            transparent 75%,
            #ecebd2 74%
          );
        "
      ></div>
    </div>
  </section>
</template>

<style scoped>
.shadow-text {
  text-shadow: 0.008em 10px black, 0 1px black;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.ellipsis {
  @supports (-webkit-line-clamp: 3) {
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

@media (min-width: 1366px) and (max-width: 1440px) {
  .height {
    @apply h-screen;
  }
}
</style>

<script lang="ts" setup>
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { z } from "zod";
import {
  FormField,
  FormItem,
  FormControl,
  FormLabel,
  FormMessage,
} from "~/components/Form";

// const heroes = [
//   {
//     campaignArea: "mental health",
//     circlePrimaryColor: "#EC3051",
//     circleSecondaryColor: "#FF5F7B",
//   },
//   {
//     campaignArea: "Ocean protection",
//     circlePrimaryColor: "#0047A5",
//     circleSecondaryColor: "#3E7FD5",
//   },
//   {
//     campaignArea: "Education",
//     circlePrimaryColor: "#00A34D",
//     circleSecondaryColor: "#8DDBB2",
//   },
// ];

const store = useStaffStore();

const schema = z.object({
  email: z.string().email("Invalid email"),
  password: z.string(),
});

type FormInput = z.input<typeof schema>;
type FormOutput = z.output<typeof schema>;

const isLoading = ref(false);
const errorMessage = ref("");

const { handleSubmit } = useForm<FormInput, FormOutput>({
  validationSchema: toTypedSchema(schema),
});

const onSubmit = handleSubmit(async (values) => {
  login(values);
});

async function login({ email, password }: FormInput) {
  isLoading.value = true;

  try {
    await store.signIn(email, password);
    navigateTo("/dashboard");
  } catch (err: any) {
    errorMessage.value = err.message;
  }

  isLoading.value = false;
}
</script>
