import { useDonorAccessToken } from "~/domains/auth/composables";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const $api = $fetch.create({
    baseURL: config.public.apiBase,
    onRequest({ options }) {
      const token = useDonorAccessToken();
      if (token.value) {
        options.headers.set("Authorization", `Bearer ${token.value}`);
      }
    },
  });

  const $staffApi = $fetch.create({
    baseURL: config.public.apiBase,
    onRequest({ options }) {
      const token = useStaffStore().staffToken;
      if (token) {
        options.headers.set("Authorization", token);
      }
    },
  });

  return {
    provide: {
      api: $api,
      staffApi: $staffApi,
    },
  };
});
