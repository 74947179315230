<template>
  <div
    class="relative grid grid-rows-[290px_183px] xl:grid-rows-[412px_246px] bg-[--primary-color]"
    :style="{
      '--primary-color': props.brandPrimaryColor,
      '--second-color': props.brandSecondaryColor,
    }"
  >
    <div class="w-full h-full bg-[--primary-color]">
      <div class="relative w-full h-full">
        <div
          class="absolute bottom-0 w-full h-2/3 rounded-t-full bg-[--second-color]"
        ></div>
        <NuxtImg
          :src="props.avatar"
          class="absolute bottom-0 left-0 right-0 w-full h-[280px] xl:h-[400px] object-contain"
          loading="lazy"
          :quality="80"
          :placeholder="[75]"
        />
      </div>
    </div>
    <div
      class="flex flex-col justify-between w-full h-full p-4 bg-[#FFFFF6] z-10"
    >
      <div class="grid grid-cols-[3fr_2fr] xl:grid-cols-[2fr_1fr] h-[84px]">
        <div>
          <p class="font-bold text-2xl xl:text-[40px] leading-8">
            {{ props.name }}
          </p>
          <div
            class="px-3 xl:px-4 xl:mt-2 w-fit border-2 border-solid border-black rounded-full bg-[#EBEAD2]"
          >
            <p class="font-semibold text-[13px] xl:text-base">
              {{ props.tags[0]?.name }}
            </p>
          </div>
        </div>
        <div class="flex flex-col items-end">
          <NuxtImg
            :src="`/circles/${props.subdomain}/badges/inner-circle.png`"
            class="w-[3.8rem] xl:w-auto h-11 xl:h-[60px] rounded-md object-contain"
            loading="lazy"
            :quality="80"
            :placeholder="[75]"
          />
          <p class="xl-[13px] xl:text-base font-semibold xl:mt-1">
            {{ props.members }} members
          </p>
        </div>
      </div>
      <button
        class="text-[32px] text-[#FFFFF6] h-[57px] xl:h-[77px] font-bold bg-black rounded-xl"
      >
        join
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type Circle } from "~/composables/useCircles";

const props = defineProps<Circle>();
</script>
