<template>
  <nuxt-link
    :to="props.to"
    :target="props.target"
    class="link"
    :class="{
      'btn-outline': props.outline,
    }"
    @click="handleInput"
  >
    <img v-if="props.icon" :src="props.icon" />
    <span>{{ props.label }}</span>
  </nuxt-link>
</template>

<script lang="ts" setup>
interface ILinkProps {
  to: string;
  target?: string;
  label?: string;
  icon?: string;
  outline?: boolean;
}

const props = defineProps<ILinkProps>();

const emit = defineEmits<{
  (e: "click", value: boolean): void;
}>();

function handleInput(): MouseEvent {
  emit("click", true);
  return;
}
</script>

<style scoped lang="scss">
.link {
  @apply flex items-center justify-center relative text-lg rounded-lg text-white bg-gray-700 text-center py-2 px-4 w-full whitespace-nowrap min-w-fit h-11;

  img {
    @apply w-7 h-7 absolute top-1/2 left-1 transform -translate-y-1/2;
    // #ffffff
    filter: invert(100%) sepia(60%) saturate(2%) hue-rotate(127deg)
      brightness(113%) contrast(101%);
  }

  &.btn-outline {
    @apply border-2 border-gray-700 bg-transparent text-black;

    img {
      // Color black
      filter: invert(0%) sepia(0%) saturate(7469%) hue-rotate(217deg)
        brightness(108%) contrast(108%);
    }
  }
}
</style>
