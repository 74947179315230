<template>
  <section
    class="flex flex-col justify-center items-center w-full bg-[#ECEBD2]"
  >
    <div
      class="w-[350px] xl:w-[1024px] py-[104px] xl:py-[142px] text-[36px] xl:text-[64px] font-Kontesa leading-9 xl:leading-[4rem] font-bold"
    >
      <p class="text-center">imagine...</p>
      <p class="text-center">
        millions of members giving $1/mo together to solve global challenges
      </p>
    </div>
    <HomeGalleryCarousel :items="gallery" />
  </section>
</template>

<script setup>
const gallery = [
  "/circles/peterbond/gallery/image-1.jpg",
  "/circles/peterbond/gallery/image-2.jpg",
  "/circles/moseswest/gallery/image-1.jpg",
  "/circles/moseswest/gallery/image-2.jpg",
  "/circles/shabani/gallery/image-1.jpg",
  "/circles/shabani/gallery/image-2.jpg",
  "/circles/leehutchinson/gallery/image-1.jpg",
  "/circles/leehutchinson/gallery/image-2.jpg",
  "/circles/alok/gallery/image-1.jpg",
  "/circles/alok/gallery/image-2.jpg",
  "/circles/juixxe/gallery/image-1.jpg",
  "/circles/juixxe/gallery/image-2.jpg",
  "/images/home/__mock__/d6938a63a78c3d309c76b05e6069d4a7.png",
];
</script>
