import * as Sentry from "@sentry/nuxt";

const environment = useRuntimeConfig().public.environment;

if (environment !== "local") {
  Sentry.init({
    enabled: environment !== "local",
    dsn: "https://e9cdcb304faa98939804c01571d63015@o558932.ingest.us.sentry.io/4507935460163584",
    environment: environment,
  });
}
