<template>
  <header class="header">
    <h1 class="text-3xl font-bold text-gray-700">The Giving Circle</h1>
    <div class="link-wrapper">
      <UiLink to="/login" label="Login" outline />
      <UiLink to="/signup" label="Sign up" />
    </div>
  </header>
</template>

<style scoped lang="scss">
.header {
  @apply w-full flex justify-between py-3 px-4 shadow-md bg-white;

  .link-wrapper {
    @apply flex gap-2 w-full;

    max-width: 220px;
  }
}
</style>
