<template>
  <section
    class="flex-col w-full min-h-screen bg-[#ECEBD2] font-Kontesa overflow-hidden"
  >
    <div class="flex flex-col items-center w-full pt-[80px] xl:pt-48">
      <p
        class="text-shadow text-center w-4/5 xl:w-full text-[64px] xl:text-9xl text-[#FFFFF6] font-bold mb-[80px] xl:mb-[156px] leading-[4rem]"
      >
        Browse circles
      </p>
      <HomeBrowseCirclesCarousel :items="circles" />
    </div>
  </section>
</template>

<script lang="ts" setup>
const circles = await useCircles();
</script>
