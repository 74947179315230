<template>
  <div class="text-base">
    <DashboardSideNav />

    <div class="flex flex-1 flex-col md:pl-72">
      <main
        class="flex-1 px-4 sm:px-8 md:px-20 bg-whitemin-h-screen"
      >
        <div class="py-8">
          <slot></slot>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts" setup></script>
