<template>
  <section
    class="flex flex-col items-center w-full xl:min-h-[900px] pt-24 xl:pt-32 bg-[#ECEBD2]"
  >
    <div class="mb-[64px] xl:mb-24">
      <p
        class="max-w-[300px] xl:max-w-[544px] text-[40px] xl:text-[64px] text-center font-Kontesa font-bold leading-8 xl:leading-[4rem]"
      >
        Help us build a better world
      </p>
    </div>
    <transition name="fade" mode="out-in">
      <HomeHeroes
        v-if="currentHero"
        :key="currentHeroIndex"
        v-bind="currentHero"
      />
    </transition>
    <HomeGalleryCarousel :items="gallery" :speed="1" />
  </section>
</template>

<script lang="ts" setup>
const circles = await useCircles();

const currentHeroIndex = ref(0);
const currentHero = ref(circles[currentHeroIndex.value]);

const switchHero = () => {
  currentHeroIndex.value = (currentHeroIndex.value + 1) % circles.length;
  currentHero.value = circles[currentHeroIndex.value];
};

const gallery = [
  "/circles/peterbond/gallery/image-1.jpg",
  "/circles/peterbond/gallery/image-2.jpg",
  "/circles/moseswest/gallery/image-1.jpg",
  "/circles/moseswest/gallery/image-2.jpg",
  "/circles/shabani/gallery/image-1.jpg",
  "/circles/shabani/gallery/image-2.jpg",
  "/circles/leehutchinson/gallery/image-1.jpg",
  "/circles/leehutchinson/gallery/image-2.jpg",
  "/circles/alok/gallery/image-1.jpg",
  "/circles/alok/gallery/image-2.jpg",
  "/circles/juixxe/gallery/image-1.jpg",
  "/circles/juixxe/gallery/image-2.jpg",
];

onMounted(() => {
  setInterval(switchHero, 3000);
});
</script>

<style scoped>
.bg-gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 85.09%, rgba(0, 0, 0, 0.3) 99.4%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
