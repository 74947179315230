
import * as vercelRuntime$qa1kx7UJkJ from '/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "ogImage": 1200
  },
  "presets": {},
  "provider": "vercel",
  "domains": [
    "giving-circles.co"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$qa1kx7UJkJ, defaults: {} }
}
        