import routerOptions0 from "/vercel/path0/packages/ddc-giving-circle/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.15_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_vqisfekk54ejpsfuqaqwblaaim/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/vercel/path0/packages/ddc-giving-circle/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}