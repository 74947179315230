<template>
  <div class="flex flex-col min-h-dvh relative bg-cream-low">
    <div
      class="absolute top-0 md:static z-50 w-full p-6 md:px-4 shrink-0 container mx-auto"
    >
      <Header />
    </div>

    <main class="grow basis-0">
      <slot />
    </main>

    <footer class="shrink-0">
      <slot name="footer">
        <Footer />
      </slot>
    </footer>
  </div>
</template>
