<template>
  <div
    class="relative flex flex-col items-center mx-8"
    :style="{
      '--ring-color': circlePrimaryColor,
      '--circle-color': circleSecondaryColor,
    }"
  >
    <div
      v-if="false"
      class="absolute w-[248.5px] lg:w-[384.1px] h-[248.5px] lg:h-[384.1px] z-[25]"
      :class="!stopRotation ? 'translation' : ''"
    >
      <div
        v-if="circleStyles.length > 0"
        v-for="n in circlesSize"
        :key="n"
        class="absolute w-4 h-4 lg:w-6 lg:h-6 rounded-full border border-solid border-[--ring-color] bg-[--circle-color] overflow-hidden"
        :class="!stopRotation ? 'rotation' : ''"
        :style="circleStyles[n - 1]"
      >
        <NuxtImg :src="`/images/home/__mock__/${n}.png`" />
      </div>
    </div>
    <div
      class="relative flex items-center justify-center rounded-full mx-6 w-[248.5px] lg:w-[384.1px] h-[248.5px] lg:h-[384.1px]"
    >
      <div
        class="absolute top-0 h-1/2 w-full rounded-t-full bg-[--ring-color]"
      ></div>
      <div
        class="half-ring absolute bottom-0 h-[51%] w-full bg-[--ring-color] z-20"
      ></div>
      <div
        class="rounded-full bg-[--circle-color] w-[187px] lg:w-[289.24px] h-[187px] lg:h-[289.24px] z-10"
      >
        <NuxtImg
          :src="avatar"
          class="absolute h-[225px] lg:h-[380px] rounded-b-full -translate-y-10 lg:-translate-y-16 object-cover"
          :alt="who"
          sizes="178 lg:300"
          loading="lazy"
          :quality="80"
          :placeholder="[95]"
        />
      </div>
    </div>
    <p
      class="text-shadow-thin absolute bottom-12 lg:bottom-14 font-Kontesa text-[#ECEBD2] text-5xl lg:text-[80px] -tracking-[3%] font-bold text-nowrap z-30"
    >
      {{ who }}
    </p>
    <button
      class="font-Kontesa font-bold text-base lg:text-2xl mt-4 px-4 h-[35px] lg:h-[52px] w-[170px] lg:w-auto lg:min-w-[263px] border-2 border-solid border-black rounded-full bg-[#F1F0DB] hover:bg-[#F5F4DA] z-30"
    >
      {{ campaignArea }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from "vue";

interface CircleStyle {
  top: string;
  left: string;
}

const emit = defineEmits(["submitCircles"]);

const { defaultCircleStyles } = defineProps<{
  avatar: string;
  who: string;
  campaignArea: string;
  circlePrimaryColor: string;
  circleSecondaryColor: string;
  stopRotation?: boolean;
  defaultCircleStyles?: CircleStyle[];
}>();

const circlesSize = 33;
const circleStyles = ref<CircleStyle[]>([]);

const setCircleData = () => {
  const isMobile = window.innerWidth <= 768;
  const centerX = isMobile ? 125 : 190;
  const centerY = centerX;
  const positionData = isMobile
    ? { min_x: -40, min_y: -40, max_x: 270, max_y: 270 }
    : { min_x: -50, min_y: -50, max_x: 400, max_y: 400 };
  const { min_x, max_x, min_y, max_y } = positionData;
  const stdDevX = (max_x - min_x) / 6;
  const stdDevY = (max_y - min_y) / 6;
  const minDistance = centerX;
  const maxDistance = 300;

  updateCirclePositions(
    centerX,
    centerY,
    stdDevX,
    stdDevY,
    minDistance,
    maxDistance
  );
};

// Transformação de Box-Muller
const generateNormalRandom = (mean = 0, stdDev = 1) => {
  const u1 = Math.random();
  const u2 = Math.random();
  const randStdNormal =
    Math.sqrt(-2.0 * Math.log(u1)) * Math.sin(2.0 * Math.PI * u2);
  return mean + stdDev * randStdNormal;
};

const isWithinRange = (
  x: number,
  y: number,
  centerX: number,
  centerY: number,
  minDistance: number,
  maxDistance: number
) => {
  const dx = x - centerX;
  const dy = y - centerY;
  const distance = Math.sqrt(dx * dx + dy * dy);
  return distance >= minDistance && distance <= maxDistance;
};

const getRandomPosition = (
  centerX: number,
  centerY: number,
  stdDevX: number,
  stdDevY: number,
  minDistance: number,
  maxDistance: number
) => {
  let x, y;
  do {
    x = generateNormalRandom(centerX, stdDevX);
    y = generateNormalRandom(centerY, stdDevY);
  } while (!isWithinRange(x, y, centerX, centerY, minDistance, maxDistance));
  return { x, y };
};

const updateCirclePositions = (
  centerX: number,
  centerY: number,
  stdDevX: number,
  stdDevY: number,
  minDistance: number,
  maxDistance: number
) => {
  if (defaultCircleStyles?.length && defaultCircleStyles?.length > 0) {
    circleStyles.value = defaultCircleStyles;
    return;
  }

  circleStyles.value = Array.from({ length: circlesSize }, () => {
    const position = getRandomPosition(
      centerX,
      centerY,
      stdDevX,
      stdDevY,
      minDistance,
      maxDistance
    );
    return {
      top: `${position.y}px`,
      left: `${position.x}px`,
    };
  });

  emit("submitCircles", circleStyles.value);
};

onMounted(() => {
  setCircleData();
});
</script>

<style scoped>
.half-ring {
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
  background: radial-gradient(
    50% 100% at top,
    #0000 75%,
    var(--ring-color) 74%
  );
}

.text-shadow-thin {
  text-shadow: 2.5px 4.5px black, -2px 0 black, 0 2px black, 0 -2px black,
    1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;
}

.translation {
  -webkit-animation: spin 60s linear infinite;
  -moz-animation: spin 60s linear infinite;
  animation: spin 60s linear infinite;
}

.rotation {
  -webkit-animation: anti-spin 60s linear infinite;
  -moz-animation: anti-spin 60s linear infinite;
  animation: anti-spin 60s linear infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes anti-spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
</style>
