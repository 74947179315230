<template>
  <div>
    <!-- Mobile nav -->
    <div class="h-[48px] md:hidden">
      <div
        class="fixed bg-white shadow w-full top-0 z-10 border-b pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden"
      >
        <button
          type="button"
          class="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-blue"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Icon name="heroicons:bars-3" class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>

    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-40 md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4"
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <Icon class="h-6 w-6 text-white" name="heroicons:x-mark" />
                  </button>
                </div>
              </TransitionChild>

              <nav
                class="mt-5 h-0 flex-1 flex flex-col justify-between overflow-y-auto px-2"
              >
                <div class="space-y-1">
                  <NuxtLink
                    draggable="false"
                    v-for="item in navigation"
                    :key="item.name"
                    @click="sidebarOpen = false"
                    :href="item.to"
                    :class="[
                      isInCurrentPath(item.to)
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                    ]"
                  >
                    <Icon
                      :class="[
                        isInCurrentPath(item.to)
                          ? 'text-gray-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 h-6 w-6',
                      ]"
                      class="h-6 w-6"
                      :name="item.icon"
                    />
                    {{ item.name }}
                  </NuxtLink>
                  <a
                    class="text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md"
                    :href="staffStore.organizationPageUrl"
                    target="_blank"
                  >
                    <Icon
                      name="lucide:external-link"
                      class="text-gray-400 mr-3 flex-shrink-0 h-6 w-6"
                    />
                    My giving circle
                  </a>
                </div>
                <button
                  type="button"
                  class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                  @click="staffStore.signOut"
                >
                  <Icon
                    class="h-6 w-6 text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0"
                    name="heroicons-outline:logout"
                  />
                  Logout
                </button>
              </nav>
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="bg-gray-50 hidden md:fixed md:inset-y-0 md:flex md:w-72">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-grow flex-col overflow-y-auto pt-5">
        <NuxtLink to="/dashboard" class="inline-flex px-5">
          <div
            class="flex items-center justify-center border border-solid border-black bg-[#F2F1DC] rounded-full cursor-pointer"
          >
            <p class="font-Kontesa font-semibold text-xl px-3">GC</p>
          </div>
        </NuxtLink>
        <nav
          class="mt-5 flex flex-grow flex-col gap-1 justify-between pb-4 px-3"
        >
          <div class="relative flex flex-col gap-1 select-none">
            <div
              v-if="currentNav"
              class="transition-all ease-in-out duration-300 bg-brand-blue w-[3px] rounded-r-xl h-[38px] absolute left-0"
              :style="{
                top: `${currentNav.index * 38 + currentNav.index * 4}px`,
              }"
            />
            <NuxtLink
              v-for="item in navigation"
              :key="item.name"
              :to="item.to"
              class="border transition-all rounded-xl px-3 py-1.5 tracking-[0.2px]"
              :class="[
                isInCurrentPath(item.to)
                  ? 'bg-white text-brand-blue shadow'
                  : 'text-gray-600 hover:bg-white hover:border-inherit hover:shadow border-transparent hover:text-black',
                'group flex items-center',
              ]"
            >
              <Icon
                v-show="isInCurrentPath(item.to)"
                class="size-6 text-brand-blue shadow mr-3 flex-shrink-0"
                :name="item.icon.replace('-outline', '-solid')"
              />
              <Icon
                v-show="!isInCurrentPath(item.to)"
                class="size-6 mr-3 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                :name="item.icon"
              />
              {{ item.name }}
            </NuxtLink>
            <a
              class="border transition-all rounded-xl px-3 py-1.5 tracking-[0.2px] text-gray-600 hover:bg-white hover:border-inherit hover:shadow border-transparent hover:text-black group flex items-center"
              :href="staffStore.organizationPageUrl"
              target="_blank"
            >
              <Icon
                name="lucide:external-link"
                class="text-gray-400 mr-3 flex-shrink-0 h-6 w-6"
              />
              My giving circle
            </a>
          </div>
          <button
            type="button"
            class="border transition-all rounded-xl px-3 py-1.5 tracking-[0.2px] text-gray-600 hover:bg-white hover:border-inherit hover:shadow border-transparent hover:text-black group flex items-center"
            @click="staffStore.signOut"
          >
            <Icon
              class="text-gray-400 mr-3 flex-shrink-0 h-6 w-6"
              name="heroicons-outline:logout"
            />
            Logout
          </button>
        </nav>
      </div>

      <!-- Sidebar decorator -->
      <div
        class="bg-white h-full w-10 border-l rounded-l-[30px] shadow-[-2px_-2px_6px_0_rgba(0,0,0,0.03)]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import dayjs from "dayjs";

const route = useRoute();
const currentPath = computed(() => route.fullPath);

const staffStore = useStaffStore();
const organizationCreatedBeforeAugust2024 = computed(() =>
  dayjs.utc(staffStore.organizationCreatedAt).isBefore(dayjs.utc("2024-08-01"))
);

const sidebarOpen = ref(false);

const isMemberPage = useIsMemberPage();
const navigation = computed(() => {
  let nav = [
    {
      name: "Overview",
      to: "/dashboard",
      icon: "heroicons-outline:home-modern",
    },
    {
      name: "Members",
      to: "/dashboard/members",
      icon: "heroicons-outline:user-group",
    },
    ...(organizationCreatedBeforeAugust2024.value
      ? [
          {
            name: "Mailchimp",
            to: "/dashboard/mailchimp",
            icon: "heroicons-outline:envelope",
          },
          {
            name: "Share",
            to: "/dashboard/share",
            icon: "heroicons:share",
          },
          {
            name: "Prizes",
            to: "/dashboard/prizes",
            icon: "heroicons:gift",
          },
          {
            name: "Settings",
            to: "/dashboard/settings",
            icon: "heroicons-outline:cog-6-tooth",
          },
          {
            name: "Page editor",
            to: "/editor",
            icon: "heroicons-outline:wrench-screwdriver",
          },
        ]
      : [
          {
            name: "Feed",
            to: "/dashboard/updates",
            icon: "heroicons-outline:newspaper",
          },
          {
            name: "Tiers",
            to: "/dashboard/tiers",
            icon: "heroicons-outline:gift",
          },
          {
            name: "Campaigns",
            to: "/dashboard/campaigns",
            icon: "mage:goals",
          },
          {
            name: "Settings",
            to: "/dashboard/settings",
            icon: "heroicons-outline:cog-6-tooth",
          },
        ]),
    {
      name: "Email",
      to: "/dashboard/integrations",
      icon: "heroicons-outline:mail",
    },
  ];

  if (!isMemberPage.value) {
    nav[1] = {
      name: "Partners",
      to: "/dashboard/partners",
      icon: "heroicons-outline:user-group",
    };
  }

  return nav;
});

const currentNav = computed(() => {
  const index = navigation.value.findIndex(
    (nav) => nav.to === currentPath.value
  );
  const nav = navigation.value[index];

  if (!nav) {
    return null;
  }

  return {
    ...nav,
    index,
  };
});

const isInCurrentPath = (path: string) => {
  if (path === "/dashboard") return currentPath.value === path;

  return currentPath.value.startsWith(path);
};
</script>
