<template>
  <section class="relative flex-col w-full h-screen">
    <header class="shrink-0" name="header">
      <Header
        class="pt-10 px-6 xl:px-16"
        text="Community-driven collective mega-donations"
      />
    </header>

    <div class="block xl:hidden mt-9 z-10">
      <p
        class="font-Kontesa font-medium text-base lg:text-2xl text-center text-[#ECEBD2]"
      >
        Community-driven collective mega-donations
      </p>
      <p
        class="block xl:hidden text-shadow font-Kontesa text-[#ECEBD2] text-[64px] xl:text-[156px] text-center font-bold leading-[4rem]"
      >
        giving circles
      </p>
    </div>
    <HomeHeroGivingCircles
      v-if="started"
      :current-hero="circles[modularIndex(pointer() + 2)]"
      :class="
        currentHeroIndex === modularIndex(pointer() + 2)
          ? `${animate} -z-10`
          : '-z-20'
      "
      class="absolute top-0 left-0 right-0 w-full h-full"
      @animationend="switchHero()"
    />
    <HomeHeroGivingCircles
      v-if="started"
      :current-hero="circles[modularIndex(pointer() + 1)]"
      :class="
        currentHeroIndex === modularIndex(pointer() + 1)
          ? `${animate} -z-10`
          : '-z-20'
      "
      class="absolute top-0 left-0 right-0 w-full h-full"
      @animationend="switchHero()"
    />
    <HomeHeroGivingCircles
      :current-hero="circles[pointer(1)]"
      :class="[
        currentHeroIndex === pointer(1) ? `${animate} -z-10` : '-z-20',
        currentHeroIndex === pointer(1) + 1 ? '-z-30' : '',
      ]"
      class="absolute top-0 left-0 right-0 w-full h-full -z-10"
      @animationend="switchHero()"
    />
  </section>
</template>

<script lang="ts" setup>
const circles = await useCircles();

const currentHeroIndex = ref(0);
const started = ref(false);
const animate = ref("");

const modularIndex = (value: MaybeRefOrGetter<number>) => {
  return toValue(value) % circles.length;
};

/**
 * Calculates a pointer index based on the current hero index with an optional offset.
 * The pointer is aligned to a modular group of 3 and wraps around based on `circles.length`.
 *
 * @param {number} [offset] - An optional offset to add to the current hero index.
 * @returns {number} The computed pointer index within the modular bounds.
 */
const pointer = (offset?: number) => {
  return modularIndex(
    Math.floor((currentHeroIndex.value + (offset ?? 0)) / 3) * 3
  );
};

const switchHero = () => {
  currentHeroIndex.value = modularIndex(currentHeroIndex.value + 1);
};

let interval: NodeJS.Timeout | null = null;
onMounted(() => {
  interval = setInterval(() => {
    started.value = true;
    animate.value = "animate";
    if (interval) clearInterval(interval);
  }, 500);
});
</script>

<style scoped>
.animate {
  animation-name: up-enter;
  animation-duration: 1s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes up-enter {
  0% {
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      90% 95.58763542715812%,
      80% 91.60674963265869%,
      70% 88.44662770638385%,
      60% 86.41629342133615%,
      50% 85.71429024383093%,
      40% 86.40926605380244%,
      30% 88.43326016746909%,
      20% 91.58834911474578%,
      10% 95.5660012903994%,
      0% 99.97724781547875%
    );
  }
  100% {
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 100%,
      100% 0%,
      90% -4.412364572841887%,
      80% -8.393250367341311%,
      70% -11.553372293616146%,
      60% -13.583706578663854%,
      50% -14.285709756169066%,
      40% -13.59073394619755%,
      30% -11.566739832530917%,
      20% -8.411650885254222%,
      10% -4.4339987096006%,
      0% -0.02275218452124675%
    );
  }
}
</style>
