<template>
  <section class="relative pt-24 bg-[#ecebd2] overflow-hidden">
    <div
      class="flex flex-col items-center w-full min-h-[25dvh] xl:h-auto bg-[#0047a5]"
    >
      <HomeWaitlist class="absolute mx-4 z-20" />

      <div
        class="absolute -top-[10%] lg:-top-[7%] xl:top-auto w-dvw h-[52dvh] overflow-hidden"
        style="
          background: radial-gradient(
            78% 87% at bottom,
            #0047a5 75%,
            #ecebd2 74%
          );
        "
      >
        <div
          class="absolute w-dvw h-[50dvh] overflow-hidden z-10"
          style="
            background: radial-gradient(
              78% 87% at bottom,
              transparent 75%,
              #ecebd2 74%
            );
          "
        ></div>
      </div>
    </div>
  </section>
</template>
