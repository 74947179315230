
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_93aV6iRKGFs8Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/campaigns/[id].vue?macro=true";
import { default as create5G24Hie3GiMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/campaigns/create.vue?macro=true";
import { default as indexEyMfQBOXo1Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/campaigns/index.vue?macro=true";
import { default as index0aRO2d8yJKMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/index.vue?macro=true";
import { default as indexu5bm5jbzZWMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/integrations/index.vue?macro=true";
import { default as klaviyoyZ2AoP7OBtMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/integrations/klaviyo.vue?macro=true";
import { default as integrationsA5T85N6iwNMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/integrations.vue?macro=true";
import { default as mailchimpOnLIzaqcwiMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/mailchimp.vue?macro=true";
import { default as membersfnz2yCgTfaMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/members.vue?macro=true";
import { default as partnersugT9MkKKS8Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/partners.vue?macro=true";
import { default as prizesMZQquN7xDoMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/prizes.vue?macro=true";
import { default as settings0cB6DaqlYRMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/settings.vue?macro=true";
import { default as share8NcljYC2iZMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/share.vue?macro=true";
import { default as _91slug_93xC5U57BfF8Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/tiers/[slug].vue?macro=true";
import { default as index2Psk4b8nkmMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/tiers/index.vue?macro=true";
import { default as new2qEwGMlk9aMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/tiers/new.vue?macro=true";
import { default as _91slug_93lyLDgXeLzLMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/[slug].vue?macro=true";
import { default as index2rbxrwMCFqMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/index.vue?macro=true";
import { default as new_45pollA9rhfTrSDoMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/new-poll.vue?macro=true";
import { default as newI94a0jrXVIMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/new.vue?macro=true";
import { default as dashboardg0LoEWPDICMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/dashboard.vue?macro=true";
import { default as loginIWItxI5gmFMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/donor/login.vue?macro=true";
import { default as editorHa8vJ0FGSeMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/editor.vue?macro=true";
import { default as forgot_45passwordTyI3fDiPMuMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/forgot-password.vue?macro=true";
import { default as homeRY4jSg4AkaMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/home.vue?macro=true";
import { default as indexB8TuzdeIomMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/index.vue?macro=true";
import { default as login85ATqQ5mVHMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/login.vue?macro=true";
import { default as indexSmhTzUgYijMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/mailchimp/redirect/index.vue?macro=true";
import { default as profileIOMbTil01UMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/profile.vue?macro=true";
import { default as rare_45galazN99gFdB4DMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/rare-gala.vue?macro=true";
import { default as rare_45impact_45selenaAuGb7tQDIOMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/rare-impact-selena.vue?macro=true";
import { default as rare_45impact_45testzoh8uepSjIMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/rare-impact-test.vue?macro=true";
import { default as reset_45password8C8gNhl3e3Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/reset-password.vue?macro=true";
import { default as signupN5ayRxnVAPMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/signup.vue?macro=true";
import { default as callback71Etxl6Qx5Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/stripe/callback.vue?macro=true";
import { default as checkoutfk3dlHvkRgMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/checkout.vue?macro=true";
import { default as giveawaysAKAsMcVca3Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/giveaways.vue?macro=true";
import { default as impact_45feedwyRSfnXsAqMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/impact-feed.vue?macro=true";
import { default as indexRcc3sfuwk6Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/index.vue?macro=true";
import { default as donateWpVS8l3uQ9Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/partners/[partnerSlug]/donate.vue?macro=true";
import { default as sweepstakeseKlgsqcns1Meta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/sweepstakes.vue?macro=true";
import { default as index50EU6bKMZfMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/welcome/index.vue?macro=true";
import { default as _91slug_93vxbnjzOUJlMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug].vue?macro=true";
import { default as testTe24S6sl6gMeta } from "/vercel/path0/packages/ddc-giving-circle/pages/test.vue?macro=true";
export default [
  {
    name: dashboardg0LoEWPDICMeta?.name,
    path: "/dashboard",
    meta: dashboardg0LoEWPDICMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-campaigns-id",
    path: "campaigns/:id()",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/campaigns/[id].vue")
  },
  {
    name: "dashboard-campaigns-create",
    path: "campaigns/create",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/campaigns/create.vue")
  },
  {
    name: "dashboard-campaigns",
    path: "campaigns",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/campaigns/index.vue")
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/index.vue")
  },
  {
    name: integrationsA5T85N6iwNMeta?.name,
    path: "integrations",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/integrations.vue"),
    children: [
  {
    name: "dashboard-integrations",
    path: "",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/integrations/index.vue")
  },
  {
    name: "dashboard-integrations-klaviyo",
    path: "klaviyo",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/integrations/klaviyo.vue")
  }
]
  },
  {
    name: "dashboard-mailchimp",
    path: "mailchimp",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/mailchimp.vue")
  },
  {
    name: "dashboard-members",
    path: "members",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/members.vue")
  },
  {
    name: "dashboard-partners",
    path: "partners",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/partners.vue")
  },
  {
    name: "dashboard-prizes",
    path: "prizes",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/prizes.vue")
  },
  {
    name: "dashboard-settings",
    path: "settings",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/settings.vue")
  },
  {
    name: "dashboard-share",
    path: "share",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/share.vue")
  },
  {
    name: "dashboard-tiers-slug",
    path: "tiers/:slug()",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/tiers/[slug].vue")
  },
  {
    name: "dashboard-tiers",
    path: "tiers",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/tiers/index.vue")
  },
  {
    name: "dashboard-tiers-new",
    path: "tiers/new",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/tiers/new.vue")
  },
  {
    name: "dashboard-updates-slug",
    path: "updates/:slug()",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/[slug].vue")
  },
  {
    name: "dashboard-updates",
    path: "updates",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/index.vue")
  },
  {
    name: "dashboard-updates-new-poll",
    path: "updates/new-poll",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/new-poll.vue")
  },
  {
    name: "dashboard-updates-new",
    path: "updates/new",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/dashboard/updates/new.vue")
  }
]
  },
  {
    name: "donor-login",
    path: "/donor/login",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/donor/login.vue")
  },
  {
    name: "editor",
    path: "/editor",
    meta: editorHa8vJ0FGSeMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/editor.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/forgot-password.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/login.vue")
  },
  {
    name: "mailchimp-redirect",
    path: "/mailchimp/redirect",
    meta: indexSmhTzUgYijMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/mailchimp/redirect/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileIOMbTil01UMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/profile.vue")
  },
  {
    name: "rare-gala",
    path: "/rare-gala",
    meta: rare_45galazN99gFdB4DMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/rare-gala.vue")
  },
  {
    name: "rare-impact-selena",
    path: "/rare-impact-selena",
    meta: rare_45impact_45selenaAuGb7tQDIOMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/rare-impact-selena.vue")
  },
  {
    name: "rare-impact-test",
    path: "/rare-impact-test",
    meta: rare_45impact_45testzoh8uepSjIMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/rare-impact-test.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/reset-password.vue")
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/signup.vue")
  },
  {
    name: "stripe-callback",
    path: "/stripe/callback",
    meta: callback71Etxl6Qx5Meta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/stripe/callback.vue")
  },
  {
    name: _91slug_93vxbnjzOUJlMeta?.name,
    path: "/subdomain/:slug()",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug].vue"),
    children: [
  {
    name: "subdomain-slug-checkout",
    path: "checkout",
    meta: checkoutfk3dlHvkRgMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/checkout.vue")
  },
  {
    name: "subdomain-slug-giveaways",
    path: "giveaways",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/giveaways.vue")
  },
  {
    name: "subdomain-slug-impact-feed",
    path: "impact-feed",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/impact-feed.vue")
  },
  {
    name: "subdomain-slug",
    path: "",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/index.vue")
  },
  {
    name: "subdomain-slug-partners-partnerSlug-donate",
    path: "partners/:partnerSlug()/donate",
    meta: donateWpVS8l3uQ9Meta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/partners/[partnerSlug]/donate.vue")
  },
  {
    name: "subdomain-slug-sweepstakes",
    path: "sweepstakes",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/sweepstakes.vue")
  },
  {
    name: "subdomain-slug-welcome",
    path: "welcome",
    meta: index50EU6bKMZfMeta || {},
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/subdomain/[slug]/welcome/index.vue")
  }
]
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/packages/ddc-giving-circle/pages/test.vue")
  }
]