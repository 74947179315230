<template>
  <div class="w-screen max-w-[1905] mx-auto overflow-x-hidden">
    <div
      ref="snapContainer"
      class="flex gap-4 lg:gap-6 overflow-x-scroll no-scrollbar snap-x pb-10 px-6"
    >
      <div
        v-if="items.length > 0"
        v-for="(item, index) in items"
        :key="item.id"
        class="max-w-slide min-w-[360px] h-slide my-1 rounded-3xl overflow-hidden shadow-black shadow-[4px_4px_0px_0px]"
        :ref="setRef(index)"
      >
        <HomeBrowseCirclesCard v-bind="item" />
      </div>
    </div>

    <div class="flex justify-center w-full mt-[40px]">
      <div
        class="flex justify-between w-44 h-[84px] p-[10px] rounded-full bg-white"
      >
        <button
          @click="scrollToPrevious"
          class="flex justify-center items-center text-xl font-bold w-16 h-16 rounded-full bg-[#F5F4DA] hover:bg-[#F1F0DB] shadow-black shadow-[4px_4px_0px_0px]"
        >
          <IconsRight />
        </button>
        <button
          @click="scrollToNext"
          class="flex justify-center items-center text-xl font-bold w-16 h-16 mr-1 rounded-full bg-[#F5F4DA] hover:bg-[#F1F0DB] shadow-black shadow-[4px_4px_0px_0px]"
        >
          <IconsRight class="rotate-180" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type VNodeRef } from "vue";
import { type Circle } from "~/composables/useCircles";

const props = defineProps<{
  items: Circle[];
}>();

function setRef(index: number) {
  return (el: VNodeRef | undefined) => {
    cardsRef.value[index] = el as HTMLElement | undefined;
    return el;
  };
}

const cardsRef = ref<Array<HTMLElement | undefined>>(
  Array(props.items.length).fill(null)
);
const snapContainer = ref(null) as Ref<HTMLElement | null>;
const currentCardIndex = ref(0);

function scrollToSlideByIndex(index: number) {
  if (snapContainer.value) {
    snapContainer.value.scrollTo({
      left: cardsRef.value[index]?.offsetLeft || 0,
      behavior: "smooth",
    });
  }
}

const scrollToPrevious = () => {
  if (currentCardIndex.value > 0) {
    currentCardIndex.value -= 1;
    scrollToSlideByIndex(currentCardIndex.value - 1);
  }
};

const scrollToNext = () => {
  if (currentCardIndex.value < props.items.length - 1) {
    currentCardIndex.value += 1;
    scrollToSlideByIndex(currentCardIndex.value);
  }
};
</script>

<style scoped>
.max-w-slide {
  max-width: 290px !important;
}

@media (min-width: 1280px) {
  .max-w-slide {
    max-width: 389px !important;
  }
}
</style>
