<template>
  <div>
    <div
      class="bg-gradient relative flex flex-col items-center justify-between h-full"
    >
      <div class="w-full h-full overflow-hidden">
        <NuxtImg
          :src="currentHero?.background"
          sizes="100vw md:100vw"
          class="absolute bottom-0 h-full w-full object-cover object-top lg:inset-0 -z-10"
          alt="hero bg"
          loading="lazy"
          :quality="80"
          :placeholder="[95]"
        />
        <div
          class="absolute top-0 left-0 flex justify-center items-center w-full h-full"
        >
          <div
            class="absolute top-0 left-0 flex justify-center items-center w-full h-full"
          >
            <div class="relative w-full h-2/3">
              <div
                class="absolute inline-flex items-center justify-center w-full h-full"
              >
                <p
                  class="hidden xl:block text-shadow font-Kontesa text-[#ECEBD2] text-[128px] 2xl:text-[156px] text-right font-bold w-1/3 z-30"
                >
                  giving
                </p>
                <div
                  class="relative w-[248.5px] lg:w-[496px] h-[248.5px] lg:h-[452px]"
                >
                  <div class="absolute top-0 left-[50%] -translate-x-[50%]">
                    <HomeHeroCircle
                      v-if="currentHero"
                      :avatar="currentHero.avatar ?? ''"
                      :who="currentHero.name ?? ''"
                      :campaignArea="currentHero.tags[0]?.name ?? ''"
                      :circle-primary-color="
                        currentHero.brandPrimaryColor ?? ''
                      "
                      :circle-secondary-color="
                        currentHero.brandSecondaryColor ?? ''
                      "
                      :stop-rotation="stopRotation"
                      :default-circle-styles="defaultCircleStyles"
                      @submit-circles="submitCircles"
                    />
                  </div>
                </div>
                <p
                  class="hidden xl:block text-shadow font-Kontesa text-[#ECEBD2] text-[128px] 2xl:text-[156px] text-left font-bold w-1/3 z-30"
                >
                  circles
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p
        class="font-Kontesa font-medium text-base lg:text-2xl text-center text-[#ECEBD2] w-[280px] xl:w-1/5 pb-10"
      >
        Join a circle to make impact & win prizes with your favorite creators!
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type Circle } from "~/composables/useCircles";

interface CircleStyle {
  top: string;
  left: string;
}

defineProps<{
  currentHero?: Circle;
  stopRotation?: boolean;
  defaultCircleStyles?: CircleStyle[];
}>();

const emit = defineEmits(["submitCircles"]);

const submitCircles = (circles: CircleStyle[]) => {
  emit("submitCircles", circles);
};
</script>

<style scoped>
.bg-gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 85.09%, rgba(0, 0, 0, 0.3) 99.4%);
}
</style>
