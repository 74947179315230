import posthog from "posthog-js";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  if (config.public.environment === "production") {
    const client = posthog.init(config.public.posthog.publicKey, {
      api_host: useBaseDomainURL("/ingest"),
      ui_host: "https://us.posthog.com",
      person_profiles: "identified_only",
      capture_pageview: false,
    });

    const subdomain = useSubdomain();
    const router = useRouter();
    router.afterEach((to) => {
      nextTick(() => {
        posthog.capture(
          "$pageview",
          {
            current_url: to.fullPath,
            ...(subdomain.value && {
              $groups: {
                circle: subdomain.value,
              },
            }),
          },
          { transport: "sendBeacon" }
        );
      });
    });

    return {
      provide: {
        posthog: client,
      },
    };
  }
});
