<template>
  <div ref="popover" class="fixed w-full max-w-sm transform -translate-y-1/2">
    <div class="relative">
      <button
        type="button"
        :class="popoverOpen ? '' : 'text-opacity-90'"
        class="group inline-flex items-center rounded-md bg-brand-blue px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        @click="togglePopover"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-6 h-6"
        >
          <path
            fill-rule="evenodd"
            d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <div
          v-if="popoverOpen"
          class="absolute right-1/2 z-10 mt-3 w-screen max-w-[350px] -translate-x-1/2 transform px-4 sm:px-0"
        >
          <div
            class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div class="relative grid gap-2 bg-white p-5">
              <div class="flex flex-col gap-2">
                <label class="input-wrapper" for="backgroundColor">
                  Background color
                  <div class="flex gap-1">
                    <ColorPicker
                      :color="brandColors.background"
                      @change="(color) => setColor(color, 'background')"
                      @cancel="resetColor('background')"
                    />
                    <UiTextInput
                      id="backgroundColor"
                      v-model="brandColors.background"
                      @focus="ignoreColorChange = true"
                      @blur="onColorBlur('background')"
                    />
                  </div>
                </label>
                <label class="input-wrapper" for="backgroundColor">
                  Shadow color
                  <div class="flex gap-1">
                    <ColorPicker
                      :color="brandColors.shadowColor"
                      @change="(color) => setColor(color, 'shadowColor')"
                      @cancel="resetColor('shadowColor')"
                    />
                    <UiTextInput
                      id="shadowColor"
                      v-model="brandColors.shadowColor"
                      @focus="ignoreColorChange = true"
                      @blur="onColorBlur('shadowColor')"
                    />
                  </div>
                </label>
                <label class="input-wrapper" for="textColor">
                  Text color
                  <div class="flex gap-1" @click.stop="">
                    <ColorPicker
                      :color="brandColors.textColor"
                      @change="(color) => setColor(color, 'textColor')"
                      @cancel="resetColor('textColor')"
                    />
                    <UiTextInput
                      id="textColor"
                      v-model="brandColors.textColor"
                      @focus="ignoreColorChange = true"
                      @blur="onColorBlur('textColor')"
                    />
                  </div>
                </label>
                <label class="input-wrapper" for="copyColor">
                  Copy color
                  <div class="flex gap-1">
                    <ColorPicker
                      :color="brandColors.copyColor"
                      @change="(color) => setColor(color, 'copyColor')"
                      @cancel="resetColor('copyColor')"
                    />
                    <UiTextInput
                      id="copyColor"
                      v-model="brandColors.copyColor"
                      @focus="ignoreColorChange = true"
                      @blur="onColorBlur('copyColor')"
                    />
                  </div>
                </label>
                <label class="input-wrapper" for="copyColor">
                  <b class="text-base">Sphere Gradient Colors</b>
                  <div class="flex gap-1">
                    <div>
                      <p>First color</p>
                      <div class="flex gap-1">
                        <ColorPicker
                          :color="sphereGradients.firstColor"
                          @change="
                            (color) => setSphereColor(color, 'firstColor')
                          "
                          @cancel="resetSphereColor('firstColor')"
                          :options="{ opacity: true }"
                        />
                        <UiTextInput
                          id="sphereFirstColor"
                          v-model="sphereGradients.firstColor"
                          @focus="ignoreColorChange = true"
                          @blur="onGradientColorBlur('firstColor')"
                        />
                      </div>
                    </div>
                    <div>
                      <p>Last color</p>
                      <div class="flex gap-1">
                        <ColorPicker
                          :color="sphereGradients.lastColor"
                          @change="
                            (color) => setSphereColor(color, 'lastColor')
                          "
                          @cancel="resetSphereColor('lastColor')"
                          :options="{ opacity: true }"
                        />
                        <UiTextInput
                          id="sphereLastColor"
                          v-model="sphereGradients.lastColor"
                          @focus="ignoreColorChange = true"
                          @blur="onGradientColorBlur('lastColor')"
                        />
                      </div>
                    </div>
                  </div>
                </label>
                <!-- <p class="text-lg">Theme</p>
                <div class="flex gap-2">
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium hover:bg-gray-100"
                    :class="{ selected: selectedTheme === 'light' }"
                    @click="selectTheme('light')"
                  >
                    Light
                  </button>
                  <button
                    type="button"
                    class="inline-flex justify-center rounded-md border border-slate-800 bg-slate-900 px-4 py-2 text-sm font-medium text-white hover:bg-slate-800 disabled:opacity-70 transition-opacity duration-200"
                    :class="{ selected: selectedTheme === 'dark' }"
                    @click="selectTheme('dark')"
                  >a
                    Dark
                  </button>
                </div> -->
              </div>

              <div class="mt-4 flex justify-between">
                <button
                  type="button"
                  class="inline-flex justify-center rounded-md border border-gray-200 bg-gray-100 px-4 py-2 text-sm font-medium hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  @click="resetToDefault"
                >
                  Reset
                </button>
                <button
                  type="button"
                  class="inline-flex justify-center rounded-md border border-green-400 bg-green-500 px-4 py-2 text-sm font-medium text-white hover:bg-green-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-700 focus-visible:ring-offset-2 disabled:opacity-70 transition-opacity duration-200"
                  @click="saveStyle"
                  :disabled="saveDisabled"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { stringToHexColor } from "~~/utils/StringToHexColor";

const pageStore = usePageStore();

pageStore.$subscribe(() => {
  const shouldUpdateBackgrond =
    pageStore.pageStyle.brandColors.background !== brandColors.value.background;
  const shouldUpdateTextColor =
    pageStore.pageStyle.brandColors.textColor !== brandColors.value.textColor;

  const shouldUpdateCopyColor =
    pageStore.pageStyle.brandColors.copyColor !== brandColors.value.copyColor;

  const shouldUpdate =
    shouldUpdateBackgrond || shouldUpdateTextColor || shouldUpdateCopyColor;

  if (shouldUpdate)
    brandColors.value = Object.assign({}, pageStore.pageStyle.brandColors);
});

const popoverOpen = ref(false);

const brandColors = ref<{
  background: string;
  textColor: string;
  copyColor: string;
  shadowColor: string;
}>(Object.assign({}, pageStore.pageStyle.brandColors));

const startingColors = ref<{
  background: string;
  textColor: string;
  copyColor: string;
  shadowColor: string;
}>(Object.assign({}, pageStore.pageStyle.brandColors));

const sphereGradients = ref<{
  firstColor: string;
  lastColor: string;
}>(Object.assign({}, pageStore.pageStyle.sphereGradientColors));

const startingSphereGradients = ref<{
  firstColor: string;
  lastColor: string;
}>(Object.assign({}, pageStore.pageStyle.sphereGradientColors));

const selectedTheme = ref<"light" | "dark">(pageStore.pageStyle.selectedTheme);

const saveDisabled = computed(() => false);

function resetToDefault(): void {
  brandColors.value = { ...startingColors.value };
  nextTick(() => {
    saveStyle();
  });
}

const ignoreColorChange = ref(false);

function setColor(
  color: string,
  key: "background" | "textColor" | "copyColor" | "shadowColor"
) {
  if (ignoreColorChange.value) return;
  brandColors.value[key] = color;
}

function setSphereColor(color: string, key: "firstColor" | "lastColor") {
  if (ignoreColorChange.value) return;
  sphereGradients.value[key] = color;
}

function resetColor(
  key: "background" | "textColor" | "copyColor" | "shadowColor"
) {
  brandColors.value[key] = startingColors.value[key];
}

function resetSphereColor(key: "firstColor" | "lastColor") {
  sphereGradients.value[key] = startingSphereGradients.value[key];
}

function saveStyle(): void {
  pageStore.savePageStyle({ ...brandColors.value }, selectedTheme.value, {
    ...sphereGradients.value,
  });
}

function selectTheme(theme: "dark" | "light"): void {
  selectedTheme.value = theme;
}

function togglePopover(): void {
  popoverOpen.value = !popoverOpen.value;
  nextTick(() => {
    if (popoverOpen.value) {
      document.addEventListener("mousedown", mouseDownListener);
      document.addEventListener("click", clickedOutsideListener);
    } else {
      document.removeEventListener("mousedown", mouseDownListener);
      document.removeEventListener("click", clickedOutsideListener);
    }
  });
}

const popover = ref<HTMLElement | null>(null);

const lastMouseDownX = ref(0);
const lastMouseDownY = ref(0);
const lastMouseDownWasOutside = ref(false);

const mouseDownListener = (event: MouseEvent) => {
  const colorPickerElements = document.getElementsByClassName("pcr-app");
  if (popover.value) {
    const clickedInsideColorPicker = Array.from(colorPickerElements).some(
      (element: Element) => element.contains(event.target as Node)
    );

    lastMouseDownX.value = event.offsetX;
    lastMouseDownY.value = event.offsetY;
    lastMouseDownWasOutside.value =
      !popover.value.contains(event.target as Node) &&
      !clickedInsideColorPicker;
  }
};

function clickedOutsideListener(event: MouseEvent): void {
  const deltaX = event.offsetX - lastMouseDownX.value;
  const deltaY = event.offsetY - lastMouseDownY.value;
  const distSq = deltaX * deltaX + deltaY * deltaY;
  const isDrag = distSq > 3;
  const isDragException = isDrag && !lastMouseDownWasOutside.value;

  const colorPickerElements = document.getElementsByClassName("pcr-app");
  if (popover.value) {
    const clickedInsideColorPicker = Array.from(colorPickerElements).some(
      (element: Element) => element.contains(event.target as Node)
    );

    const clickedOutsidePopover =
      !popover.value.contains(event.target as Node) &&
      !clickedInsideColorPicker;

    if (popover && clickedOutsidePopover && !isDragException) {
      popoverOpen.value = false;
      document.removeEventListener("mousedown", mouseDownListener);
      document.removeEventListener("click", clickedOutsideListener);
    }
  }
}

function onColorBlur(
  key: "background" | "textColor" | "copyColor" | "shadowColor"
): void {
  ignoreColorChange.value = false;

  nextTick(() => {
    brandColors.value[key] = stringToHexColor(brandColors.value[key]);
  });
}

function onGradientColorBlur(key: "firstColor" | "lastColor"): void {
  ignoreColorChange.value = false;

  nextTick(() => {
    sphereGradients.value[key] = stringToHexColor(
      sphereGradients.value[key],
      true
    );
  });
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  @apply text-base;
}
.color-sample {
  @apply w-10 h-10 rounded-lg border border-gray-200;
}

.selected {
  outline: 4px solid #518ff3;
}
</style>
