<template>
  <div class="bg-[#0047A5]">
    <div
      ref="el"
      class="relative flex flex-col w-full h-[400vh] lg:flex-row pt-10"
    >
      <div class="flex flex-col justify-center w-full h-[100vh] sticky top-0">
        <p
          class="title-text text-center text-[#ECEBD2] xl:leading-[8rem] font-Kontesa font-bold text-shadow"
        >
          We believe
        </p>

        <div class="flex justify-center circle-mt">
          <div class="w-fit p-4 rounded-full bg-[#ECEBD2] bg-opacity-10">
            <div class="p-4 rounded-full bg-[#ECEBD2] bg-opacity-10">
              <div class="p-4 rounded-full bg-[#ECEBD2] bg-opacity-10">
                <div class="p-4 rounded-full bg-[#ECEBD2] bg-opacity-10">
                  <div
                    class="relative flex justify-center items-center circle-size rounded-full bg-[#ECEBD2] bg-opacity-100"
                  >
                    <!-- Circulo SVG de progresso -->
                    <svg
                      class="absolute top-0 left-0 w-full h-full"
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="50"
                        cy="50"
                        :r="RADIUS"
                        fill="none"
                        stroke="#0A56BB"
                        stroke-width="1"
                        stroke-linecap="round"
                        :stroke-dasharray="circumference"
                        :stroke-dashoffset="
                          circumference -
                          circumference * Math.min(1, (1 - progress) * (4 / 3))
                        "
                        class="transition-all duration-[0.01s]"
                      />
                    </svg>
                    <!-- Conteúdo interno -->
                    <transition name="fade" mode="out-in" class="p-0 xl:p-4">
                      <p
                        :key="activeKey"
                        class="text-center max-w-[147px] xl:max-w-[248px] text-lg"
                      >
                        {{ items[activeKey] }}
                      </p>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mt-[7.5vh] flex justify-center transition-all duration-700 text-[#0A56BB] text-shadow shadow-text number-text number-leading font-Kontesa font-bold"
        >
          <div
            v-for="(_, key) of items"
            :key="key"
            class="flex gap-4 transition-all duration-700 px-4 lg:px-16"
            :class="{
              'text-[#ECEBD2] -translate-y-10  xl:-translate-y-16':
                `${activeKey}` === `${key}`,
            }"
          >
            <div>0{{ key }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="relative flex flex-col items-center w-full mt-8 xl:mt-[156px] pb-24"
    >
      <p
        class="ellipsis block overflow-hidden h-24 xl:h-auto w-[341px] xl:w-[872px] text-3xl xl:text-[64px] text-center text-[#ECEBD1] font-Kontesa font-bold leading-8 xl:leading-[4rem] z-10"
      >
        We’re changing impact from me to we, by unleaching the power of the
        crowd
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
const el = ref<HTMLDivElement | null>(null);
const { progress } = useScrollProgress(el);

const RADIUS = 49;

const circumference = computed(() => {
  return 2 * Math.PI * RADIUS;
});

const activeKey = computed(() => {
  if (progress.value < 0.5) {
    return 3;
  }
  if (progress.value < 0.75) {
    return 2;
  }
  return 1;
});

const items: { [key in 1 | 2 | 3]: string } = {
  "1": "Together we can make a real difference.",
  "2": "Instead of begging billionaires for funds, we can create our own billionaire.",
  "3": "Community is built on shared values and impact.",
};
</script>

<style scoped>
.title-text {
  @apply md:text-8xl min-[1024px]:text-8xl min-[1400px]:text-9xl text-[4rem] xl:text-8xl 2xl:text-9xl;
}

.circle-size {
  @apply w-[12.8rem] h-[12.8rem] md:w-[20rem] md:h-[20rem] lg:w-[12.8rem] lg:h-[12.8rem] min-[1280px]:w-[11.5rem] min-[1280px]:h-[11.5rem] min-[1366px]:w-48 min-[1366px]:h-48 min-[1400px]:w-64 min-[1400px]:h-64 min-[1440px]:w-56 min-[1440px]:h-56 min-[1600px]:w-56 min-[1600px]:h-56 min-[1680px]:w-64 min-[1680px]:h-64 min-[1920px]:w-[15.2rem] min-[1920px]:h-[15.2rem];
}

.circle-mt {
  @apply mt-10 min-[1024px]:mt-10 min-[1280px]:mt-8 min-[1366px]:mt-14 min-[1400px]:mt-32 min-[1440px]:mt-32 min-[1600px]:mt-24 min-[1680px]:mt-[8rem] min-[1920px]:mt-[3rem];
}

.number-text {
  @apply text-[7rem] md:text-[14rem] min-[1024px]:text-[12rem] min-[1280px]:text-[14rem] min-[1366px]:text-[16rem] min-[1400px]:text-[20rem] min-[1440px]:text-[16rem] min-[1600px]:text-[16rem] min-[1680px]:text-[20rem] min-[1920px]:text-[20rem];
}

.number-leading {
  @apply leading-[10rem] lg:leading-[13rem] min-[1400px]:leading-[19rem] min-[1440px]:leading-[15rem] min-[1600px]:leading-[15rem] min-[1680px]:leading-[19rem] min-[1920px]:leading-[18rem];
}

.shadow-text {
  text-shadow: 0.008em 10px black, 0 1px black;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
