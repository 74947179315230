<template>
  <div class="w-full overflow-hidden">
    <div
      class="flex marquee-container"
      ref="scrollContainer"
      @wheel="handleScroll"
      @mousedown="stopAutoScroll"
      @mouseup="startAutoScroll"
      @mouseleave="startAutoScroll"
    >
      <div
        class="w-fit flex gap-4 px-4 pb-14"
        :style="{ transform: `translateX(${posX}px)` }"
        ref="marquee"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          class="relative min-w-[196px] h-[121px]"
        >
          <NuxtImg
            :src="item"
            class="w-full h-full rounded-lg object-cover"
            loading="lazy"
            :quality="80"
            :placeholder="[95]"
          />
        </div>
        <div
          v-for="(item, index) in items"
          :key="index + '_copy'"
          class="relative min-w-[196px] h-[121px]"
        >
          <NuxtImg
            :src="item"
            class="w-full h-full rounded-lg object-cover"
            loading="lazy"
            :quality="80"
            :placeholder="[95]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{ speed?: number; items: Array<any> }>(),
  { speed: 0 }
);

const posX = ref(0);
const isScrolling = ref(false);
const scrollSpeed = props.speed!;
let animationFrame: number | null = null;
let lastTimestamp = performance.now();

const updatePosition = (timestamp: number) => {
  if (!isScrolling.value) {
    const deltaTime = timestamp - lastTimestamp;
    posX.value -= scrollSpeed * (deltaTime / 16);
  }
  lastTimestamp = timestamp;
  animationFrame = requestAnimationFrame(updatePosition);
};

const marqueeWidth = ref(0);
const marquee = ref<HTMLDivElement>();

onMounted(() => {
  marqueeWidth.value = marquee.value?.offsetWidth ?? 0;
  animationFrame = requestAnimationFrame(updatePosition);
});

onUnmounted(() => {
  if (animationFrame) cancelAnimationFrame(animationFrame);
});

const handleScroll = (event: WheelEvent) => {
  isScrolling.value = true;
  posX.value -= (event.deltaX + event.deltaY) * 0.15;
};

const stopAutoScroll = () => (isScrolling.value = true);
const startAutoScroll = () => (isScrolling.value = false);

watchEffect(() => {
  if (posX.value <= -marqueeWidth.value / 2) {
    posX.value = 0;
  }
});
</script>

<style lang="scss" scoped>
.marquee-container {
  @apply relative w-full;
}

.marquee {
  @apply flex;
  transition: transform 0.1s linear;
}
</style>
