<template>
  <div>
    <header v-if="!pageStore.pageLoading" class="edit-header">
      <div class="flex items-center">
        <NuxtLink class="mr-2" to="/dashboard">
          <img
            class="w-8 h-8 rounded-full p-1 transition duration-200 ease-in-out hover:bg-gray-200"
            src="/images/icons/arrow-uturn-left.svg"
          />
        </NuxtLink>
        <UiLoadingIcon
          v-if="pageStore.isPageSaving"
          size="20px"
          background-color="bg-black"
        />
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#518FF3"
          class="w-7 h-7 md:w-8 md:h-8"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>

        <p class="px-1 py-1 rounded-xl text-sm md:text-base">
          {{ pageStore.isPageSaving ? "Saving..." : "Saved" }}
        </p>
      </div>
      <div
        class="hidden md:block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        <ClientOnly>
          <p
            v-if="info"
            class="text-lg md:text-xl font-bold leading-4 text-center"
          >
            {{ info.organization.name }}
          </p>
        </ClientOnly>
        <p class="font-normal text-normal md:text-xl leading-4 text-center">
          Landing page editor
        </p>
      </div>
      <div class="flex items-center">
        <label
          for="editCheckBox"
          class="flex items-center gap-2 cursor-pointer text-sm md:text-base"
        >
          {{ editorSwitchText }}
          <input
            class="apple-switch cursor-pointer"
            id="editCheckBox"
            type="checkbox"
            :checked="pageStore.onEditMode"
            @input="toggleEditMode"
          />
        </label>
        <div class="pl-2">
          <PageStyleEditor />
        </div>
      </div>
    </header>
    <main :class="{ content: !pageStore.pageLoading }">
      <slot />
    </main>
  </div>
</template>

<script lang="ts" setup>
const { info } = useStaffStore();
const pageStore = usePageStore();

const editorSwitchText = computed(() =>
  pageStore.onEditMode ? "Editor" : "Preview"
);

function toggleEditMode(): void {
  pageStore.toggleEditMode();
}
</script>

<style lang="scss" scoped>
.edit-header {
  @apply px-2.5 pl-2 pr-16 flex justify-between items-center bg-white shadow-lg w-full relative;

  position: fixed;
  z-index: 50;
  height: 72px;
  max-height: 72px;
}

.content {
  padding-top: 72px;
}

.apple-switch {
  position: relative;
  outline: none;
  width: 50px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #d9dadc;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background: transparent;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  }

  &:checked {
    box-shadow: inset 20px 0 0 0 #518ff3;
    border-color: #518ff3;
  }

  &:checked:after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
  }
}
</style>
