<template>
  <ConfigProvider :use-id="useIdFunction">
    <NuxtLoadingIndicator color="#0048A6" :throttle="0" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <VueQueryDevtools />
  </ConfigProvider>
</template>

<script setup lang="ts">
import { useQueryClient } from "@tanstack/vue-query";
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";
import { authQueries } from "./domains/auth/queries";
const useIdFunction = () => useId();

const queryClient = useQueryClient();
await queryClient.prefetchQuery(authQueries.self());
await queryClient.prefetchQuery(authQueries.preferences());
</script>
